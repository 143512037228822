<template>
	<v-sheet class="asset" id="asset" style="height: calc(100vh - 88px)">
		<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
			<template v-slot:title>
				<span>Extend Warranty </span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-row>
						<v-col md="12">
							<v-form
								ref="warrantyForm"
								v-model.trim="formValid"
								lazy-validation
								v-on:submit.stop.prevent="updateOrCreate"
							>
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-row>
										<v-col md="9">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 160px); position: relative"
											>
												<v-row>
													<v-col md="12" class="py-0">
														<p class="middle-header-background my-4 mt-7 px-4">
															Warranty Information<br />
															<span>
																<v-icon small>mdi-progress-question</v-icon> Specify the warranty details for your
																asset
															</span>
														</p>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col md="3" class="my-auto py-0">
														<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
															>Warranty Period</label
														>
													</v-col>
													<v-col md="9" class="py-0">
														<v-layout>
															<v-flex md6>
																<NumberInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	:id="`checkout-duration-${uniqFieldId}`"
																	placeholder="Duration"
																	v-model="warranty.warranty_duration"
																	v-on:keyup="(e) => updateWarrantyDuration(e, 'warranty_duration')"
																></NumberInput>
															</v-flex>
															<v-flex md6>
																<SelectInput
																	hide-details
																	:items="warrantyDurationList"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	placeholder="Duration"
																	v-model="warranty.warranty_duration_type"
																	v-on:change="(e) => updateWarrantyDuration(e, 'warranty_duration_type')"
																></SelectInput>
															</v-flex>
														</v-layout>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="cost-price" class="btx-label mt-2 required">Start Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="warranty-start-date"
															placeholder="Start Date"
															:min-date="warrantyDetails.minDate"
															v-model="warranty.warranty_start_date"
															v-on:change="(e) => updateWarrantyDuration(e, 'start_date')"
															:rules="[vrules.required(warranty.warranty_start_date, 'Warranty Start Date')]"
															:class="{
																required: !warranty.warranty_start_date,
															}"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor" class="btx-label mt-2 required">End Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="warranty-end-date"
															placeholder="End Date"
															:min-date="warranty.warranty_start_date"
															v-model="warranty.warranty_end_date"
															v-on:change="checkWarrantyDiff"
															:rules="[vrules.required(warranty.warranty_end_date, 'Warranty End Date')]"
															:class="{
																required: !warranty.warranty_end_date,
															}"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="reminder-type" class="btx-label mt-2 required">Reminder Type</label>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															dense
															id="reminder-type"
															hide-details
															v-model="warranty.reminder_type"
															placeholder="Select Reminder Type"
															:items="reminder_type_items"
															:disabled="pageLoading"
															:loading="pageLoading"
															item-text="text"
															item-value="value"
															v-on:keyup="get_reminder_values()"
														></AutoCompleteInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="reminder-start-date" class="btx-label mt-2 required"
															><template v-if="warranty.reminder_type != 1">Start </template>Date</label
														>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															dense
															id="reminder-start-date"
															hide-details
															v-model="warranty.reminder_date"
															:placeholder="
																warranty.reminder_type != 1 ? 'Select Start Date...' : 'Select Date...'
															"
															:disabled="pageLoading"
															:loading="pageLoading"
															v-on:keyup="get_reminder_values()"
															:min-date="formatDateRaw()"
															:rules="[
																vrules.required(
																	warranty.reminder_date,
																	warranty.reminder_type != 1 ? 'Start Date' : 'Date'
																),
															]"
														></DatePicker>
													</v-col>
													<template v-if="warranty.reminder_type != 1">
														<v-col md="3" class="my-auto py-0">
															<label for="reminder-frequency" class="btx-label mt-2 required">Frequency</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																dense
																id="reminder-frequency"
																hide-details
																type="number"
																v-model="warranty.frequency"
																placeholder="Enter Frequency..."
																:disabled="pageLoading"
																:loading="pageLoading"
																v-on:keyup="get_reminder_values()"
																:rules="[vrules.required(warranty.frequency, 'Frequency')]"
																class="mt-0"
															></TextInput>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="reminder-end-date" class="btx-label mt-2 required">End Date</label>
														</v-col>
														<v-col md="9" class="py-0">
															<DatePicker
																dense
																:rules="[vrules.required(warranty.reminder_end_date, 'End Date')]"
																id="reminder-end-date"
																hide-details
																v-model="warranty.reminder_end_date"
																placeholder="Select End Date..."
																:disabled="pageLoading"
																:loading="pageLoading"
																readonly
															></DatePicker>
														</v-col>
													</template>
													<v-col md="3" class="my-auto py-0">
														<label for="warranty-cost" class="btx-label mt-2">Cost</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															dense
															id="warranty-cost"
															hide-details
															type="number"
															v-model="warranty.cost"
															placeholder="Enter Cost..."
															:disabled="pageLoading"
															:loading="pageLoading"
															class="mt-0"
														></TextInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="attachment" class="btx-label mt-2">Attachment</label>
													</v-col>
													<v-col md="9" class="py-0">
														<template v-for="(data, index) in wtx_attachments">
															<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																<v-col md="5" class="py-0">
																	<v-file-input
																		v-model="data.file"
																		placeholder="Click here to select files"
																		color="blue darken-4"
																		multiple
																		outlined
																		class="mt-3"
																		prepend-icon=""
																		prepend-inner-icon="mdi-attachment"
																		hide-details
																		v-on:change="limitAttachmentWarranty($event, index)"
																	>
																	</v-file-input>
																</v-col>
																<v-col md="5" class="py-0">
																	<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																</v-col>
																<v-col md="1" class="py-0">
																	<v-btn
																		:disabled="wtx_attachments.length < 2"
																		v-on:click="removeFileWarranty(index)"
																		class="white--text mt-3"
																		depressed
																		color="red darken-4"
																		tile
																		style="margin-left: -10px"
																	>
																		<v-icon>mdi-delete</v-icon>
																	</v-btn>
																</v-col>
																<v-col md="1" class="py-0">
																	<v-btn
																		:disabled="wtx_attachments.length > 2"
																		v-on:click="addMoreWarranty()"
																		class="white--text mt-3"
																		depressed
																		color="blue darken-4"
																		tile
																		style="margin-left: -13px"
																	>
																		<v-icon>mdi-plus</v-icon>
																	</v-btn>
																</v-col>
															</v-row>
														</template>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="remark" class="btx-label mt-2">Remark</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="remark"
															placeholder="Remark"
															v-model="warranty.warranty_remark"
														></TextAreaInput>
													</v-col>
													<v-col md="12" class="min-height-40"> </v-col>
												</v-row>
											</perfect-scrollbar>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-form>
						</v-col>
					</v-row>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-row>
					<v-col md="12" class="text-right">
						<v-btn
							:loading="pageLoading"
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							v-on:click="updateOrCreate()"
						>
							Save
						</v-btn>
						<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
					</v-col>
				</v-row>
			</template>
		</CreateDialog>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import CreateDialog from "@/view/components/CreateDialog";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { GetWarranty, ExtendedWarranty, UpdateWarranty } from "@/core/lib/warranty.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import { toString, toSafeInteger } from "lodash";
import ObjectPath from "object-path";
import MomentJS from "moment-timezone";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";

export default {
	name: "inventory-create",
	title: "Create Perishables",
	data() {
		return {
			createDialog: true,
			currentDate: MomentJS().format("YYYY-MM-DD"),
			uuid: null,
			isExtend: false,
			barcodeLoading: false,
			formValid: true,
			pageLoading: false,
			reminder_type_items: [],
			warrantyDetails: {},
			warranty: {
				warranty_start_date: null,
				warranty_end_date: null,
				warranty_reminder_date: null,
				warranty_remark: null,
				reminder_end_date: null,
				frequency: null,
				reminder_date: null,
				reminder_type: 1,
				warranty_duration: 0,
				warranty_duration_type: "day",
			},
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
			search: null,
			items: [],
		};
	},
	components: {
		CreateDialog,
		TextInput,
		DatePicker,
		NumberInput,
		SelectInput,
		TextAreaInput,
		AutoCompleteInput,
	},
	methods: {
		updateWarrantyDuration(e, type) {
			let warranty_start_date = e;
			if (type != "start_date") {
				warranty_start_date =
					this.warranty && this.warranty.warranty_start_date ? this.warranty.warranty_start_date : null;
			}
			if (warranty_start_date) {
				let { warranty_duration_type, warranty_duration } = this.warranty;
				let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
				let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
				switch (warranty_duration_type) {
					case "day":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
						break;
					case "month":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
						break;
					case "year":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
						break;
				}
				/* this.$nextTick(() => {
					this.warranty.warranty_end_date = date.format("YYYY-MM-DD");
				}); */
				this.warranty.warranty_end_date = date.format("YYYY-MM-DD");
			}
		},
		checkWarrantyDiff(endDate) {
			if (this.subpart.warranty_start_date && endDate) {
				let startDate = MomentJS(this.subpart.warranty_start_date);
				let endData = MomentJS(endDate);
				let diffData = endData.diff(startDate, "days");
				this.subpart.warranty_duration_type = "day";
				this.subpart.warranty_duration = Number(diffData);
			}
		},
		getWarrantyDetails(uuid) {
			let _this = this;
			GetWarranty(uuid).then((res) => {
				_this.warrantyDetails = res;
				_this.warranty.warranty_start_date = MomentJS(_this.warrantyDetails.end_date, "YYYY-MM-DD")
					.add(1, "days")
					.format("YYYY-MM-DD");
				_this.warranty.frequency = _this.warrantyDetails.reminder_frequency;
				_this.warranty.reminder_type = _this.warrantyDetails.reminder_type;
				_this.warrantyDetails.minDate = this.warrantyDetails.end_date;
				_this.warranty.cost = _this.warrantyDetails.cost;
				if (!_this.isExtend) {
					_this.warranty.warranty_start_date = _this.warrantyDetails.start_date;
					_this.warrantyDetails.minDate = _this.currentDate;
					_this.warranty.warranty_end_date = _this.warrantyDetails.end_date;
					_this.warranty.warranty_reminder_date = _this.warrantyDetails.reminder_date;
					_this.warranty.warranty_remark = _this.warrantyDetails.remark;
					_this.warranty.reminder_end_date = _this.warrantyDetails.reminder_end_date;
					_this.warranty.reminder_date = _this.warrantyDetails.reminder_date;
					_this.warranty.reminder_type = _this.warrantyDetails.reminder_type;
				}
			});
		},
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.warrantyForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.warrantyForm.validate()) {
				return false;
			}

			const formData = {
				start_date: _this.warranty.warranty_start_date,
				end_date: _this.warranty.warranty_end_date,
				reminder_date: _this.warranty.reminder_date,
				reminder_endDate: _this.warranty.reminder_end_date,
				remark: _this.warranty.warranty_remark,
				reminder_frequency: _this.warranty.frequency,
				reminder_type: _this.warranty.reminder_type,
				attachment_warranty: this.wtx_attachments,
				cost: _this.warranty.cost,
			};

			try {
				_this.pageLoading = true;
				let warrantyData = {};
				if (this.isExtend) {
					warrantyData = await ExtendedWarranty(formData, this.uuid);
				} else {
					warrantyData = await UpdateWarranty(formData, this.uuid);
				}
				let uuid = warrantyData && warrantyData.uuid;
				console.log(uuid);
				/* _this.$router.replace({
					name: "asset-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				}); */
				_this.$router.replace({
					name: "warranty",
					query: { t: new Date().getTime() },
				});

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Asset has been created." },
				]);
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		get_reminder_values() {
			if (this.warranty.reminder_date) {
				let reminderParseDate = MomentJS(this.warranty.reminder_date);

				for (var i = 0; i < this.warranty.frequency; i++) {
					if (this.warranty.reminder_type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
					}

					if (this.warranty.reminder_type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
					}

					if (this.warranty.reminder_type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
					}

					if (this.warranty.reminder_type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}

					if (this.warranty.reminder_type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
					}

					if (this.warranty.reminder_type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}
				}

				return (this.warranty.reminder_end_date = reminderParseDate.format("YYYY-MM-DD"));
			}
			return null;
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Warranty", disabled: true },
			{ text: "Extend", disabled: true },
		]);
		const { params, query } = this.$route;
		if (params.uuid) {
			this.uuid = toString(params.uuid);
			this.getWarrantyDetails(this.uuid);
		}
		if (query.extendId) {
			this.isExtend = true;
			this.uuid = query.extendId;
			this.getWarrantyDetails(this.uuid);
		}
		this.reminder_type_items = this.localDB("reminder_type_items", []);
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	watch: {
		"warranty.reminder_date": {
			deep: true,
			handler() {
				this.get_reminder_values();
			},
		},
	},
};
</script>
